html,
body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd,
ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead,
tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer,
header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio,
video {
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0; }

article, aside, details,
figcaption, footer, header, hgroup, menu, nav, section {
  display: block; }

figure {
  display: inline-block; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  line-height: 1; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

a {
  text-decoration: none; }

input {
  line-height: normal; }

/** {
  transition: all 0.2s ease-out; }*/

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background: #000;
  color: #000;
  line-height: 180%;
}

.circle, .square, .triangle {
  margin: 0 8px;
}

.circle {
  display: inline-block;
  pointer-events: none;
  position: relative;
  width: 24px;
  height: 24px;
  font-size: 12px;
  border-radius: 50%;
  background-color: #5ef3fb;
}

.square {
  display: inline-block;
  pointer-events: none;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: #fbf38c;
  font-size: 12px;
}

.triangle {
  display: inline-block;
  pointer-events: none;
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 14px 24px 14px;
  border-color: transparent transparent #FF1461 transparent;
}

